import styled from "styled-components"

import { Divider } from "src/ui/Divider/Divider"
import { spacing } from "src/ui/spacing"
import { WizardButtonRow } from "src/ui/Wizard/WizardButtonRow"
import { TBaseWizard } from "src/ui/Wizard/wizardTypes"

export function BaseWizard({
  steps,
  currentStep,
  onNext,
  onBack,
  ProgressBar,
  HeaderBox,
  TopContent,
  ContentBox,
}: TBaseWizard) {
  const filteredSteps = steps.filter((step) => !step.skip)

  const {
    component,
    onNext: onNextOverride,
    onBack: onBackOverride,
    nextButtonLabel,
    backButtonLabel,
    nextButtonProps,
    backButtonProps,
    hideNextButton,
    hideBackButton,
    border,
    direction,
    footer,
  } = filteredSteps[currentStep]

  const ContentBoxElement = ContentBox || DefaultContentBox

  const HeaderBoxElement = HeaderBox || DefaultHeaderBox

  return (
    <>
      <HeaderBoxElement>
        {!!TopContent && TopContent}

        {!!ProgressBar && ProgressBar}
      </HeaderBoxElement>

      <ContentBoxElement>
        <ComponentArea>{component}</ComponentArea>

        <WizardButtonRow
          currentStep={currentStep}
          onNext={onNextOverride || onNext}
          onBack={onBackOverride || onBack}
          backButtonProps={backButtonProps}
          nextButtonProps={nextButtonProps}
          nextButtonLabel={nextButtonLabel}
          backButtonLabel={backButtonLabel}
          hideNextButton={hideNextButton}
          hideBackButton={hideBackButton}
          border={border}
          direction={direction}
        />

        {footer && (
          <Footer>
            <Divider /> {footer}
          </Footer>
        )}
      </ContentBoxElement>
    </>
  )
}

const ComponentArea = styled.div``

const Footer = styled.div`
  display: grid;
  gap: ${spacing.M};
`

const DefaultContentBox = styled.div`
  display: grid;
  gap: ${spacing.XL2};
`

const DefaultHeaderBox = styled.div``
